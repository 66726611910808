<template>
  <div>
    <div v-if="hasMailSelect">
      <div>
        <b-form-group :label="channelLabel">
          <v-select
            :options="channelOptions"
            v-model="currentChannel"
            placeholder="Seleziona il canale"
            v-on:option:selected="prepareEmails"
          >
            <div slot="no-options">Nessuna opzione disponibile</div>
          </v-select>
        </b-form-group>
      </div>
      <hr class="mt-2" />
      <div>
        <div v-if="hasSelectedChannel">
        <h5>
          {{recipientsLabel}}
        </h5>
        <field-multi-mail
          :label="recipientToLabel"
          v-model="recipientToValue"
          :placeholder="recipientToPlaceholder"
          :defaultEmails="recipientToEmails"
        />
        <field-multi-mail
          :label="recipientCcLabel"
          v-model="recipientCcValue"
          :placeholder="recipientCcPlaceholder"
          :defaultEmails="recipientCcEmails"
        />
        <field-multi-mail
          :label="recipientBccLabel"
          v-model="recipientBccValue"
          :placeholder="recipientBccPlaceholder"
          :defaultEmails="recipientBccEmails"
        />
      </div>
      <div v-if="!hasSelectedChannel">
        <p
          class="sendmail-channel__no-selection"
        >
          <em>
            {{noChannelSelected}}
          </em>
        </p>
      </div>
    </div>
    <hr class="mt-2" />
    <div>
      <h5>
        {{docsHeading}}
      </h5>
      <div>
        <modal-send-mail-form-documents
          v-for="doc in docsComputed"
          :key="doc.documentType"
          :item="doc"
        />
      </div>
    </div>
    <hr class="mt-2" />
    <div>
      <h5>
        {{singleSendHeading}}
      </h5>
      <b-form-checkbox v-model="singleSendCheck" name="singleSendCheck" switch>
        {{singleSendLabel}}
      </b-form-checkbox>
    </div>
    <div class="mt-3 pt-3 border-top d-flex">
      <b-btn
        size="sm"
        variant="warning"
        @click="closeModal"
      >
        Chiudi
      </b-btn>
      <div class="ms-auto">
        <b-btn
          :disabled="!mailComposed || submitted"
          size="sm"
          variant="info"
          @click="sendMailPreview"
          class="me-3"
        >
          Preview
        </b-btn>
        <b-btn
          :disabled="!mailComposed || submitted"
          size="sm"
          variant="success"
          @click="sendMail"
        >
          Invia Email
        </b-btn>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import SendmailService from '@/services/sendmail.service';
import { isNotEmpty, isPresent } from '@/utils/validators';
import { toastSuccess } from '@/utils/toast';

const FieldMultiMail = () => import('@/components/fields/FieldMultiMail.vue');
const ModalSendMailFormDocuments = () => import('./ModalSendMailFormDocuments.vue');

export default {
  name: 'ModalSendMailForm',
  props: {
    id: String,
    entityType: String,
    entityId: String,
    context: String,
    environment: String,
    onSuccess: Function,
    modalId: String,
    language: String,
    mails: Array,
    mailCode: String,
    enablePreviewMode: Function,
  },
  components: { FieldMultiMail, ModalSendMailFormDocuments },
  data() {
    return {
      submitted: false,
      previewMode: false,
      showCompany: false,
      hasErrors: false,
      error: null,
      currentChannel: null,
      channelConfig: null,
      currentMail: null,
      recipientToValue: [],
      recipientCcValue: [],
      recipientBccValue: [],
      singleSendCheck: false,
      // mails: [],
      mailConfig: {},
    };
  },
  computed: {
    hasMailSelect() {
      // return isPresent(this.currentMail?.key);
      return isPresent(this.mailCode);
    },
    channelLabel() {
      return 'Canale';
    },
    channelOptions() {
      if (!this.mailConfig?.channels) return [];
      return this.mailConfig.channels.map((item) => ({
        key: item.channel,
        label: item.channelLabel,
      }));
    },
    recipientsLabel() {
      return 'Destinatari';
    },
    hasSelectedChannel() {
      return isNotEmpty(this.channelConfig);
    },
    recipientToLabel() {
      return 'To:';
    },
    recipientToPlaceholder() {
      return 'Inserire i destinatari (To)';
    },
    recipientCcLabel() {
      return 'Cc:';
    },
    recipientCcPlaceholder() {
      return 'Inserire i destinatari (Cc)';
    },
    recipientBccLabel() {
      return 'Bcc:';
    },
    recipientBccPlaceholder() {
      return 'Inserire i destinatari (Bcc)';
    },
    noChannelSelected() {
      return 'Selezionare prima un canale';
    },
    docsHeading() {
      return 'Documenti';
    },
    singleSendHeading() {
      return 'Invio singolo';
    },
    singleSendLabel() {
      return 'Invia una mail singola ad ogni destinatario';
    },
    recipientToEmails() {
      return [...this.channelConfig.toAddress];
    },
    recipientCcEmails() {
      return [...this.channelConfig.ccAddress];
    },
    recipientBccEmails() {
      return [...this.channelConfig.bccAddress];
    },
    mailComposed() {
      const toAddr = this.recipientToValue.length !== 0;
      const docsMandatory = this.docsComputed.filter((item) => item.mandatory).length;
      const docsSelectMandatory = this.docsComputed.filter((item) => item.mandatory && item.sendAttach).length;

      let docs = true;
      if (docsMandatory > 0 && docsMandatory !== docsSelectMandatory) {
        docs = false;
      }

      return toAddr && docs;
    },
    docsComputed() {
      console.log('this.mailConfig?.documents', this.mailConfig?.documents);
      return this.mailConfig?.documents || [];
    },
  },
  mounted() {
    this.submitted = false;
    this.previewMode = false;
    if (this.mails.length === 1) {
      const item = this.mails[0];
      this.currentMail = {
        key: item.mailCode,
        label: item.label,
      };
      this.loadMailTemplate();
    }
  },
  methods: {
    loadMailTemplate() {
      this.mailConfig = {};
      if (!isPresent(this.mailCode)) return;

      SendmailService.getSendMailCompose({
        entityId: this.entityId,
        environment: this.environment,
        context: this.context,
        mailCode: this.mailCode,
        language: this.language,
      }).then((response) => {
        this.mailConfig = response.data;
      });
    },
    closeModal() {
      // console.log('close modal / reset');
      this.$bvModal.hide(this.modalId);
    },
    prepareEmails() {
      // if (!isPresent(this.currentChannel?.key)) {
      //   this.recipientToValue = [];
      //   this.recipientCcValue = [];
      //   this.recipientBccValue = [];
      //   this.channelConfig = null;
      //   return;
      // }
      const config = this.mailConfig.channels.filter((item) => item.channel === this.currentChannel?.key);
      if (config.length === 0) {
        this.recipientToValue = [];
        this.recipientCcValue = [];
        this.recipientBccValue = [];
        this.channelConfig = null;
        return;
      }

      // eslint-disable-next-line prefer-destructuring
      this.channelConfig = config[0];

      this.recipientToValue = [...this.channelConfig.toAddress];
      this.recipientCcValue = [...this.channelConfig.ccAddress];
      this.recipientBccValue = [...this.channelConfig.bccAddress];
    },
    sendMailPreview() {
      this.sendMail(true);
    },
    sendMail(showPreview) {
      console.log('send mail', showPreview);
      const { mailInfo, documents } = this.mailConfig;
      const {
        channelConfig, entityId, language, recipientToValue, recipientCcValue, recipientBccValue,
      } = this;
      const sendMail = {
        mailInfo,
        mailChannel: {
          ...channelConfig,
          toAddress: recipientToValue,
          ccAddress: recipientCcValue,
          bccAddress: recipientBccValue,
        },
        documents,
        showPreview,
      };

      this.submitted = true;
      this.$store.dispatch('sendmail/sendMailRequest', {
        entityId, language, sendMail, showPreview,
      })
        .then(() => {
          this.submitted = false;
          if (!showPreview) {
            toastSuccess('Mail inviata');
            this.closeModal();
          } else {
            console.log('active preview mode', this.enablePreviewMode);
            if (this.enablePreviewMode) this.enablePreviewMode(true);
            this.previewMode = true;
          }
        }).catch((error) => {
          console.log('send mail error', error);
          this.submitted = false;
        });

      // SendmailService.sendMailRequest({ entityId, language, sendMail })
      //   .then((response) => {
      //     if (response.status >= 200 && response.status <= 299) {
      //       this.submitted = true;
      //       if (!showPreview) {
      //         toastSuccess('Mail inviata');
      //         this.closeModal();
      //       } else {
      //         this.previewMode = true;
      //       }
      //     } else {
      //       toastUnableToComplete();
      //     }
      //   }).catch((error) => {
      //     console.warn('Unable to complete email request', error);
      //     toastUnableToComplete();
      //   });
    },
  },
};
</script>

<style scoped>

</style>
